import request from "@/utils/request";

// 获取积分订单
export function IntegralOrder(params){
    return request({
        url:'/admin/base/goldOrder',
        method: 'get',
        params
        }
    )
}

// 获取快递公司列表
export function GetExpressList(params){
    return request({
        url:'/admin/base/getExpressList',
        method: 'get',
        params
        }
    )
}

// 金币订单发货
export function GoldOrderSend(data){
    return request({
        url:'/admin/base/goldOrderSend',
        method: 'post',
        data
        }
    )
}

// 修改金币订单地址
export function GoldOrderAddress(data){
    return request({
        url:'/admin/base/goldOrderAddress',
        method: 'put',
        data
        }
    )
}